@import 'bootstrap/variables';

.week {
  width: 100%;
  height: 10px;
  border: 1px solid black;
  margin-top: 10px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  &__inner {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $pink;
  }
  &__day {
    width: 0;
    border-left: 1px dashed black;
    height: 100%;
    position: absolute;
    &--1 {
      left: 20%;
    }
    &--2 {
      left: 40%;
    }
    &--3 {
      left: 60%;
    }
    &--4 {
      left: 80%;
    }
  }
}