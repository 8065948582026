.error-404 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 56px);
  &__inner {
    text-align: center;
    font-size: 1.5em;
  }
  &__headline {
    font-size: 4em;
  }
}
