@import 'bootstrap/variables';

.app {
  position: relative;
  min-height: 100vh;
  &__login {
    padding-top: 56px;
    padding-bottom: 30px;
    &__inner {
      max-width: 400px;
      margin: 0 auto;
    }
  }
  &__search {
    @media (min-width: map-get($grid-breakpoints, "lg")){
      width: 400px !important;
    }
  }
  &__content {
    display: flex;
    height: 100vh;
    padding-top: 56px;
  }
  &__sidebar {
    top: 0;
    left: 0;
    position: fixed;
    width: 240px;
    height: 100%;
    background-color: $white;
    box-shadow: 0.15rem 0 1.75rem 0 rgba(31, 45, 65, 0.15);
    padding-top: 56px;
    display: flex;
    flex-direction: column;
    transform: translateX(-100%);
    transition: transform .3s ease-in-out;
    z-index: 10;
    overflow: auto;
    &__footer {
      margin-top: auto;
      background: $gray-200;
      width: 100%;
      padding: 12px;
    }
    &--active {
      transform: translateX(0%);
    }
    @media (min-width: map-get($grid-breakpoints, "lg")){
      transform: none;
    }
  }
  &__inner {
    min-height: 100%;
    width: 100%;
    @media (min-width: map-get($grid-breakpoints, "lg")){
      margin-left:  240px;
    }
  }
}

.btn-pink {
  color: $white;
  background-color: #ff0e88;
  border-color: #ff0e88;
  background: linear-gradient(45deg, #ff0e88 0%,#ff43a4 100%);
  &:hover {
    color: $white;
  }
}

.cursor-pointer {
  cursor: pointer;
}