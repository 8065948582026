@import '../bootstrap/variables';

.header {
  min-height: 8rem;
  padding-bottom: 6rem;
  margin-bottom: -6rem;
  background-color: $blue;
  background-image: linear-gradient(135deg, $blue 0%, rgba($purple, 0.8) 100%);
  color: $gray-100;
  &__inner {
    padding: 30px 0;
  }
  &--small {
    padding-bottom: 0;
    margin-bottom: 1rem;
    min-height: 2rem;
  }
}
