@import 'bootstrap/variables';

.customers {
  display: flex;
  height: 100%;
  position: relative;
  &__sidebar {
    width: 240px;
    height: calc(100% - 56px);
    background-color: $white;
    box-shadow: 0.15rem 0 1.75rem 0 rgba(31, 45, 65, 0.15);
    z-index: 1;
    display: block;
    position: fixed;
    top: 56px;
    left: 0;
    transform: translate(-100%);
    transition: .3s ease-in-out;
    @media (min-width: map-get($grid-breakpoints, "lg")){
      top: 56px;
      left: 240px;
      transform: none;
    }
    &--active {
      transform: translate(0);
    }
    &__search {
      padding: 12px 12px 1px;
      background-color: $white;
      position: sticky;
      top: 0;
      z-index: 3;
    }
    &__inner {
      overflow: auto;
      height: 100%;
    }
    &__toggler {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 50%;
      left: 100%;
      margin-left: -20px;
      background: $white;
      margin-top: -20px;
      padding-left: 20px;
      align-items: center;
      text-align: center;
      border-radius: 50%;
      box-shadow: 0.15rem 0 1.75rem 0 rgba(31, 45, 65, 0.15);
      z-index: -1;
      cursor: pointer;
      display: flex;
      @media (min-width: map-get($grid-breakpoints, "lg")){
        display: none;
      }
      &:hover {
        background-color: $gray-100;
      }
    }
  }
  &__content {
    width: 100%;
    @media (min-width: map-get($grid-breakpoints, "lg")){
      width: calc(100% - 240px);
      margin-left: auto
    }
  }
  &__item {
    cursor: pointer;
    &--last {
      background-color: $gray-300;
    }
  }
}
