@import 'bootstrap/variables';
.app_sidebar__main{
  .nav {
    margin-top: 20px;
    &-link {
      color: $gray-900;
      &:hover {
        color: $primary;
      }
      &.active {
        color: $primary;
        font-weight: bold;
      }
      .icon {
        margin-right: 10px;
      }
    }
    &-headline {
      margin-top: 30px;
      padding: 0 1rem;
      text-transform: uppercase;
      font-size: 70%;
      font-weight: bold;
      color: $gray-600;
    }
  }
}

.nav-tabs .nav-link.active {
  background-color: $white;
}
